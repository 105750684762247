import Meta from 'components/Layout/Meta';
import PopupModal from 'components/Layout/Modal/PopupModal';
import PageBuilder from 'components/Layout/content/PageBuilder';
import IGDisplay, { GramPostShim } from 'components/Layout/social/IGDisplay';
import { defaultMetaTags } from 'core/constants';
import { websiteSchema } from 'core/schemaGenerators';
import { NextPage } from 'next';
import { useContext } from 'react';
import { Page } from 'src/types/sanity/new-schema';
import { Article } from 'src/types/sanity/schema';
import { MyAppContext } from 'state/context/global';
import { getAllArticlesForHome, getPageBySlug } from 'utils/sanity/api';
import { withApollo } from '../apollo/apollo';
import { getLayout } from '../components/Layout/SiteLayout';

interface Props {
    data: { articles: Article[] };
    preview: boolean;
    igPosts?: GramPostShim[];
    pageData: Page;
}

const HomePage: NextPage<Props> = ({ data, preview, igPosts, pageData }) => {
    const app = useContext(MyAppContext);
    return (
        <>
            <Meta tags={defaultMetaTags} structuredData={websiteSchema()} />
            {pageData?.pageBuilder && <PageBuilder sections={pageData?.pageBuilder} />}
            {igPosts && <IGDisplay igPosts={igPosts} />}
            {app.isPopupProductDismissed ? null : <PopupModal />}
        </>
    );
};

export async function getStaticProps({ preview = false }) {
    const pageData = await getPageBySlug('home');
    const articles = await getAllArticlesForHome(false, 3);
    // const client = new Instagram({ username: 'bumblebeefarm_', password: '20Pollinator20@' }); //process.env.INSTAGRAM_PW });
    // await client.login();

    // const response = await client.getPhotosByUsername({
    //     username: 'bumblebeefarm_',
    // });

    return {
        props: {
            // igPosts: response.user.edge_owner_to_timeline_media.edges,
            pageData: pageData || null,
            preview,
            data: { articles: articles || 'Article' },
            revalidate: 60 * 60,
        },
    };
}

const HomePageWithApollo = withApollo(HomePage);
HomePageWithApollo.getLayout = getLayout;
export default HomePageWithApollo;

import { shopConfigFetcher } from 'components/admin/shopConfigFetcher';
import { ShopConfigQuery, ShopConfigResponseData } from 'utils/sanity/types';
import * as React from 'react';

const useShopConfig = (data: ShopConfigQuery) => {
    const [responseData, setResponseData] = React.useState<ShopConfigResponseData | undefined>();
    const [error, setError] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        async function getData() {
            setIsLoading(true);
            try {
                const mfd = await shopConfigFetcher(data);
                // @ts-ignore
                setResponseData(mfd);
                setIsLoading(false);
            } catch (err) {
                // @ts-ignore
                setError(err);
            }
        }
        getData();
    }, []);
    return { responseData, error, isLoading };
};

export default useShopConfig;

import { ShopConfigQuery, ShopConfigResponseData } from 'utils/sanity/types';

export async function shopConfigFetcher(data: ShopConfigQuery): Promise<unknown> {
    try {
        const res = await fetch('/api/shop-config', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        });

        return res.json();
    } catch (err) {
        console.error('err', err);
        return err;
    }
}

import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { shopifyApiUrl } from 'utils/shopifyAPiVersion';
import ShopLayout from 'components/Layout/ShopLayout';
import SiteLayout, { getLayout as getSiteLayout } from 'components/Layout/SiteLayout';
import { NextPage } from 'next';
import { LayoutPage } from 'src/types/globalTypes';

let globalApolloClient: ApolloClient<NormalizedCacheObject>;

const storefrontLink = new HttpLink({
    uri: shopifyApiUrl('sf'),
    headers: {
        'X-Shopify-Storefront-Access-Token': process.env.STOREFRONT_TOKEN,
        Accept: 'application/json',
    },
    fetch,
});
const wpLink = new HttpLink({
    uri: process.env.WP_URL,
    headers: { Accept: 'application/json' },
    fetch,
});

function initApolloClient(initialState) {
    if (!globalApolloClient) {
        globalApolloClient = new ApolloClient({
            link: ApolloLink.split(
                (operation) => operation.getContext().clientName === 'wpLink', // Routes the query to the proper client
                wpLink,
                storefrontLink,
            ),
            cache: new InMemoryCache().restore(initialState || {}),
        });
    }
    // client side page transition to an SSG page => update Apollo cache
    else if (initialState) {
        globalApolloClient.cache.restore({
            ...globalApolloClient.cache.extract(),
            ...initialState,
        });
    }
    return globalApolloClient;
}

interface WithApolloType {
    ({ apolloStaticCache, ...pageProps }: { [x: string]: any; apolloStaticCache: any }): JSX.Element;
    getInitialProps(ctx: any): Promise<Record<string, unknown>>;
    displayName: string;
    getLayout?: (page: any) => any;
}

export function withApollo(PageComponent) {
    const WithApollo: WithApolloType = ({ apolloStaticCache, ...pageProps }) => {
        // HERE WE USE THE PASSED CACHE
        const client = initApolloClient(apolloStaticCache);
        // and here we have the initialized client 🙂

        return (
            <ApolloProvider client={client}>
                <PageComponent {...pageProps} />
            </ApolloProvider>
        );
    };
    // if you also use it for SSR
    if (PageComponent.getInitialProps) {
        WithApollo.getInitialProps = async (ctx) => {
            // Run wrapped getInitialProps methods
            let pageProps = {};
            if (PageComponent.getInitialProps) {
                pageProps = await PageComponent.getInitialProps(ctx);
            }
            return pageProps;
        };
    }

    // Set the correct displayName in development
    if (process.env.NODE_ENV !== 'production') {
        const displayName = PageComponent.displayName || PageComponent.name || 'Component';

        WithApollo.displayName = `withApollo(${displayName})`;
    }
    return WithApollo;
}

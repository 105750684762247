/**
 * @description Returns the chosen API type along with the current version defined in the env; Will default to storefront if none specified
 * @param type Shopify API Type - Storefront or Admin
 */
export function shopifyApiUrl(type: 'sf' | 'admin') {
    const sfUrl = process.env.STOREFRONT_URL;
    const adminUrl = process.env.ADMIN_URL;
    if (type === 'admin') {
        return (
            adminUrl?.split(/\d\d\d\d-\d\d/).join(process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION) ||
            `https://bumblebee-flower-farm.myshopify.com/admin/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}/graphql.json`
        );
    }
    if (type === 'sf') {
        return (
            sfUrl?.split(/\d\d\d\d-\d\d/).join(process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION) ||
            `https://bumblebee-flower-farm.myshopify.com/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}/graphql.json`
        );
    }

    return `https://bumblebee-flower-farm.myshopify.com/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}/graphql.json`;
}

import SingleProduct from 'components/shop/Product/SingleProduct';
import { Product } from 'components/shop/types';
import { bbTheme } from 'components/styles/theme';
import dayjs from 'dayjs';
import useShopConfig from 'hooks/useShopConfig';
import { ShopConfigResponseData } from 'utils/sanity/types';
import React, { useContext, useEffect } from 'react';
import { MyAppContext } from 'state/context/global';
import styled from 'styled-components';
import Button from '../Buttons';
import Modal from './Modal';

export const PopupProductStyles = styled.div`
    padding-top: 2rem;

    .close-btn {
        top: -10px;
        right: -20px;
    }
`;

function handlePopupProduct(shopConfigResponse: ShopConfigResponseData, cb: (str: string) => void) {
    if (!shopConfigResponse || shopConfigResponse._type !== 'popupProduct') return null;

    const popupData = shopConfigResponse;
    // TODO Type Queries
    const product = shopConfigResponse.product as unknown as Product;

    if (product.store.slug.current) {
        cb(product.store.slug.current);
    }

    return popupData;
}

function isModalVisible(shopConfigResponse: ShopConfigResponseData): boolean {
    if (!shopConfigResponse || shopConfigResponse._type !== 'popupProduct') return false;
    const popupData = shopConfigResponse;

    if (!popupData) return false;
    const start = popupData.startDate;
    const end = popupData.endDate;

    if (!start || !end) return false;

    const today = dayjs();
    const startDate = dayjs(start);
    const endDate = dayjs(end);

    const isVisible = today.isAfter(startDate.subtract(1, 'hour')) && today.isBefore(endDate);

    return isVisible;
}

const PopupModal = ({}) => {
    const app = useContext(MyAppContext);
    const { responseData, isLoading, error: adminError } = useShopConfig({ queryName: 'popupProduct' });

    const isVisible = isModalVisible(responseData);

    useEffect(() => {
        if (responseData && responseData._type === 'popupProduct' && responseData.active) {
            handlePopupProduct(responseData, app.setPopupProduct);
        }

        return () => {
            app.setPopupProductDismissed();
        };
    }, [responseData]);

    if (!responseData || responseData._type !== 'popupProduct' || !responseData.active || isLoading || adminError) {
        return null;
    }
    const product = responseData?.product as unknown as Product;
    return (
        <>
            <Modal isOpen={!app.isPopupProductDismissed && isVisible} bgColor={bbTheme.colors.white}>
                <PopupProductStyles className="flex-gap PopupProduct-container">
                    <Button
                        text=""
                        func={() => app.setPopupProductDismissed()}
                        className="close-btn"
                        btnStyle="clear"
                        icon={{ name: 'close' }}
                    />
                    {responseData.title && <h4>{responseData.title}</h4>}
                    {responseData.tagline && <p className="note">{responseData.tagline}</p>}
                    {responseData && <SingleProduct product={product} isPopup />}
                </PopupProductStyles>
            </Modal>
        </>
    );
};

export default PopupModal;

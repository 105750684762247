import React from 'react';
import styled from 'styled-components';
import { ElementLink, MyLink } from '../Link';
import MyImage from '../MyImage';
import node from './node.json';
import ReactGA from 'react-ga';

const InstagramDisplayStyles = styled.section`
    ul {
        li {
            max-width: 19%;
        }
    }
`;

export interface GramPostShim {
    node: typeof node;
}
interface InstagramDisplayProps {
    igPosts: GramPostShim[];
}

const InstagramDisplay: React.FC<InstagramDisplayProps> = ({ igPosts }) => {
    return (
        <InstagramDisplayStyles className="InstagramDisplay-container">
            <MyLink
                external
                hrefProp={`https://www.instagram.com/${process.env.NEXT_PUBLIC_INSTAGRAM_USER}`}
                text="Follow us on Instagram"
                socialIcon={{ name: 'instagram-r' }}
                icon={{ name: 'none' }}
                className="mb-xs"
            />
            <ul className={'igPosts flex-gap'}>
                {igPosts.map(({ node }, i) => {
                    return (
                        <li key={i}>
                            {/* <a
                                href={`https://www.instagram.com/p/${node.shortcode}`}
                                target="_blank"
                               
                            > */}
                            <ReactGA.OutboundLink
                                eventLabel={'Instagram Post'}
                                to={`https://www.instagram.com/p/${node.shortcode}`}
                                target={'_blank'}
                                // trackerNames={trackerNames}
                                rel="noopener noreferrer"
                            >
                                <MyImage
                                    loader="shopify"
                                    nextImageProps={{
                                        src: node.display_resources[0].src,
                                        width: node.dimensions.width,
                                        height: node.dimensions.height,
                                        alt:
                                            node.edge_media_to_caption.edges.find((el) => el.node.text)?.node.text ||
                                            'Instagram Post',
                                        layout: 'intrinsic',
                                    }}
                                />
                            </ReactGA.OutboundLink>
                            {/* </a> */}
                            <p>{node.edge_media_to_caption.edges[0]?.node.text}</p>
                        </li>
                    );
                })}
            </ul>
        </InstagramDisplayStyles>
    );
};

export default InstagramDisplay;
